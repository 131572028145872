import { PointsLedgerConfigOverride } from '@pv-frontend/points-ledger/interfaces/pl.interface';

export const pointsLedgerConfigOverrides: PointsLedgerConfigOverride = {
  earnMore: {
    container: {
      config: {
        label: 'Available Jana Bank Points =',
        desc: 'Get exclusive offers, gift cards & much more',
        ctaLabel: 'Earn More',
        clipIconSrc: 'clip',
        heartIconSrc: 'jana-point',
      },
      cta: {
        style: {
          letterSpacing: '1px',
          textTransform: 'capitalize',
        },
      },
      label: {
        style: {
          color: 'var(--text-color)',
        },
      },
    },
    clipIcon: {
      style: {
        width: '39px',
        height: '53px',
      },
    },
    heartIcon: {
      style: {
        width: '26px',
        height: '24px',
        top: '13px',
        left: '8px',
      },
    },
    config: {
      isExternalUrl: false,
      url: '/earn-more',
    },
  },
  container: {
    heading: {
      style: {
        fontSize: '12px',
        textTransform: 'uppercase',
      },
    },
  },
  listItemContainer: {
    label: {
      style: {
        fontWeight: '700',
        fontSize: '1rem',
        color: 'var(--text-color)',
      },
    },
    date: {
      style: {
        color: 'var(--gray-tertiary)',
        fontSize: '0.857rem',
      },
    },
  },
};
