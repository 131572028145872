import { ContextualGlobalDisplayConfig } from '@pv-frontend/contextual-journey';
import { GiftVouchersConfig } from '@pv-frontend/gift-voucher-journey';
import { OrderInfoModuleConfigOverride } from '@pv-frontend/order-info';
import { PointsLedgerConfigOverride } from '@pv-frontend/points-ledger/interfaces/pl.interface';

import {
  contextualDektopJourneyConfig,
  contextualMobileJourneyConfig,
} from './contextual';
import { giftVouchersConfig } from './gift-vouchers';
import { giftVouchersDesktopConfig } from './gift-vouchers-desktop';
import { orderInfoConfigOverride } from './order-info';
import { pointsLedgerConfigOverrides } from './points-ledger';

export interface AllJourneyConfig {
  journey: GiftVouchersConfig;
  contextual: ContextualGlobalDisplayConfig;
  orderInfo: OrderInfoModuleConfigOverride;
  pointsLedger?: PointsLedgerConfigOverride;
}

export const allJourneyDetails: AllJourneyConfig = {
  journey:
    window.innerWidth < 768 ? giftVouchersConfig : giftVouchersDesktopConfig,
  contextual:
    window.innerWidth < 768
      ? contextualMobileJourneyConfig
      : contextualDektopJourneyConfig,
  orderInfo: orderInfoConfigOverride,
  pointsLedger: pointsLedgerConfigOverrides,
};
